import React, { useState } from 'react'
import Navbar from '../Components/Navbar'
import EndingCredits from '../Components/EndingCredits'
import { InlineWidget } from 'react-calendly'

function Contact() {

    const [ContactOption,setContactOption]=useState(1)

  return (
    <div class='w-full min-h-screen '>
        <Navbar ShowNavbar={true}/>
        <div class='w-full min-h-screen flex flex-col items-center space-y-20 font-font1'>
            <div class='w-full px-20'>
                <div class='w-full h-[45vh] flex justify-center items-center mt-32  bg-[#121E36] rounded-2xl px-20'>
                    <text class='text-7xl text-white font-medium'>Contact Us</text>
                </div>
            </div>
            <div class='w-full h-fit flex px-20'>
                {/* <div class='w-2/3 flex flex-col p-14 border-[2px] space-y-8'>
                    <text class='text-[#121E36] text-6xl leading-tight font-bold'>Contact Us</text>
                    <text class='font-light text-black/40 text-lg'>Bij Your Leads bieden we twee handige manieren om met ons in contact te komen: vul het contactformulier in voor al uw vragen en verzoeken, of plan direct een afspraak in via Calendly om een online bakje koffie te doen. We kijken ernaar uit elkaar te spreken. </text>
                    <div class='flex flex-col space-y-2'>
                        <text>Name</text>
                        <input class='border-[2px] py-3 px-3 outline-none' placeholder='Enter your name'/>
                    </div>
                    <div class='flex flex-col space-y-2'>
                        <text>Email Address</text>
                        <input class='border-[2px] py-3 px-3 outline-none' placeholder='Enter your email'/>
                    </div>
                    <div class='flex flex-col space-y-3'>
                        <text>Message</text>
                        <textarea rows={8} class='border-[2px] py-3 px-3 outline-none' placeholder='Hi there, I am reaching out beause I think we can collaborate...'/>
                    </div>
                    <button class='w-full h-fit py-3 px-10 bg-[#121E36] text-white' >SUBMIT</button>
                </div> */}
                <div class='w-2/3 flex flex-wrap items-center justify-center '>
                    <button onClick={()=>{setContactOption(1)}} class={`${ContactOption==1?'w-[55vh] h-[40vh] bg-gradient-to-br to-[#1A76D6] from-[#121E36] text-[#ffffff]':'w-[50vh] h-[35vh]  text-[#121E36]'} shadow-lg shadow-[#121E36]  mx-5 my-5 p-5 rounded-2xl` }>
                        <text class='text-4xl font-bold'>Schedule via Contact form</text>
                    </button>
                    <button onClick={()=>{setContactOption(2)}} class={` ${ContactOption==2?'w-[55vh] h-[40vh]  bg-gradient-to-br to-[#1A76D6] from-[#121E36] text-[#ffffff]':'w-[50vh] h-[35vh]  text-[#121E36]'} shadow-lg shadow-[#121E36]  mx-5 my-5 p-5 rounded-2xl` }>
                        <text class='text-4xl  font-bold'>Schedule via Calendly</text>
                    </button>
                </div>
                <div class='w-1/3 flex flex-col px-14 space-y-10'>
                    <div class='w-full flex flex-col space-y-4'>
                        <text>Your Leads</text>
                        <div class='flex flex-col space-y-1'>
                            <text class='font-light text-black/40 text-lg'>KVK: 77859383 </text>
                            <text class='font-light text-black/40 text-lg'>Friesestraatweg 141-96</text>
                            <text class='font-light text-black/40 text-lg'>9743 AA te Groningen</text>
                        </div>
                    </div>
                    <div class='w-full flex flex-col space-y-4'>
                        <text>Werktijden</text>
                        <div class='flex flex-col space-y-1'>
                            <text class='font-light text-black/40 text-lg'>Maandag t/m Zaterdag </text>
                            <text class='font-light text-black/40 text-lg'>Vanaf 09:00 tot 18:00</text>
                        </div>
                    </div>
                    <div class='w-full flex flex-col space-y-4'>
                        <text>CONTACT</text>
                        <div class='flex flex-col space-y-1'>
                            <button class='w-fit font-light text-black/40 text-lg underline hover:text-blue-400'>info@your-leads.nl</button>
                            <text class='font-light text-black/40 text-lg'>+31851306731</text>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class='w-full h-[0.1vh] bg-black/30 px-20'></div> */}
            <div class='w-full h-fit flex flex-col justify-between items-center px-20'>
                {/* <text class='text-[#121E36] text-6xl leading-tight font-bold'>Schedule a Meeting on Calendly</text>  */}
                {
                    ContactOption==1?
                    <div class='w-full flex flex-col p-14 border-[2px] space-y-8'>
                        <text class='text-[#121E36] text-6xl leading-tight font-bold'>Contact Us</text>
                        <text class='font-light text-black/40 text-lg'>Bij Your Leads bieden we twee handige manieren om met ons in contact te komen: vul het contactformulier in voor al uw vragen en verzoeken, of plan direct een afspraak in via Calendly om een online bakje koffie te doen. We kijken ernaar uit elkaar te spreken. </text>
                        <div class='flex flex-col space-y-2'>
                            <text>Name</text>
                            <input class='border-[2px] py-3 px-3 outline-none' placeholder='Enter your name'/>
                        </div>
                        <div class='flex flex-col space-y-2'>
                            <text>Email Address</text>
                            <input class='border-[2px] py-3 px-3 outline-none' placeholder='Enter your email'/>
                        </div>
                        <div class='flex flex-col space-y-3'>
                            <text>Message</text>
                            <textarea rows={8} class='border-[2px] py-3 px-3 outline-none' placeholder='Hi there, I am reaching out beause I think we can collaborate...'/>
                        </div>
                        <button class='w-full h-fit py-3 px-10 bg-[#121E36] text-white' >SUBMIT</button>
                    </div>
                    :
                    <InlineWidget url='https://calendly.com/desmond110a/leadsdemo' styles={{width:'100vw',height:'100vh'}}/>
                }
            </div>
        </div>
        <EndingCredits/>
    </div> 
  )
}

export default Contact