import React, { useEffect, useRef, useState } from 'react'
import HeroSection from '../Components/HeroSection'
import LeadsSection from '../Components/LeadsSection'
import Footer from '../Components/Footer'
import CallToAction from '../Components/CallToAction'
import Clients from '../Components/Clients'
import Navbar from '../Components/Navbar'
import QuoteSection from '../Components/QuoteSection'
import CoreSection from '../Components/CoreSection'
import CaseStudies from '../Components/CaseStudies'
import WorkFlow from '../Components/WorkFlow'

function LandingPage() {

  const [ShowNavbar,setShowNavbar]=useState(false)

  return (
    <div class='w-full min-h-screen'>
      <Navbar ShowNavbar={ShowNavbar}/>
      <HeroSection setShowNavbar={setShowNavbar}/>
      <CoreSection/>
      <LeadsSection/>
      <QuoteSection/>
      <WorkFlow/>
      <Clients/>
      {/* <CaseStudies/> */}
      <CallToAction/>
    </div>
  )
}

export default LandingPage