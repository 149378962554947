
import React, { useEffect, useState } from 'react';
import c1 from '../assets/Cores/c1.jpg';
import c2 from '../assets/Cores/c2.jpg';
import c3 from '../assets/Cores/c3.jpg';
import c4 from '../assets/Cores/c4.jpg';

import core1 from '../assets/Cores/core5.png'
import core2 from '../assets/Cores/core7.png'
import core3 from '../assets/Cores/core3.gif'
import core4 from '../assets/Cores/core6.png'


import { TypeAnimation } from 'react-type-animation';

function CoreSection() {
  const [Core, setCore] = useState([
    {pic:c1,asset:core1,name:'Communicatie',desc:'Communicatie is het sleutelwoord tot succes. Met open en eerlijke communicatie bouwen we het fundament voor een prettige en lange samenwerking'},
        {pic:c2,asset:core2,name:'Samenwerken',desc:'Wij werken niet vóór jou, maar mét jou. Zonder input, geen output. Met de kennis & kunde die we samen hebben kunnen we de mooiste resultaten realiseren.'},
        {pic:c3,asset:core3,name:'Begrip',desc:'Elk bedrijf is uniek en verdient een uniek marketingplan dat aansluit bij de doelen en behoeften van het bedrijf.'},
        {pic:c4,asset:core4,name:'Resultaatgericht',desc:'Het gaat uiteindelijk om het resultaat. De doelen die we vooraf samenstellen willen, moeten en zullen we halen.'},
  ]);

  const [offsetX, setOffsetX] = useState(0);
  const handleScroll = () => setOffsetX(window.pageYOffset);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div class='w-full h-fit flex overflow-hidden bg-gradient-to-b from-[#000029] to-[#ffffff] text-[#E8ECEF] py-20 font-font1 '>
      <div class='w-full h-fit flex flex-col items-center space-y-24'>
        <div class='w-fit flex flex-col items-center space-y-8'>
            <div class='flex flex-col space-y-4'>
              <TypeAnimation
                sequence={[
                  'Wij zullen luisteren naar wat je wilt',
                  500,
                  'Wij zullen je geven wat je nodig hebt',
                  500,
                ]}
                wrapper="span"
                speed={40}
                style={{ fontSize: '6vh', display: 'inline-block' }}
                repeat={Infinity}
                class='font-font1 font-medium'
              />
              <text class='text-center text-[1.8vh]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </text>
            </div>
        {/* <text class='font-font1 text-[2vh] font-light'>Onze Kenwarden</text> */}
        </div>
        <div class='w-full justify-center flex flex-wrap'>
          {Core.map((obj, index) => (
            
              <div
                class={`w-[24vw] h-fit mx-[0.5vh]  ${
                  index % 2 === 0 ? 'bg-[#121E36] text-[#E8ECEF]' : 'bg-[#E8ECEF] text-[#121E36]'
                } px-8 py-10 flex flex-col overflow-hidden items-center space-y-5 justify-between`}
              >
                <div class='flex flex-col items-center space-y-2 h-[15vh]'>
                  <text class='text-[1.8vw] font-medium'>{obj.name}</text>
                  <text class='text-center font-light text-[0.7vw]'>{obj.desc}</text>
                </div>
                <div class='w-full h-[12vh] overflow-hidden'>
                {
                    index==-1?
                    <video src={obj.asset} class='w-full h-full' typeof='video/mp4' autoPlay loop muted/>
                    :
                    <img src={obj.asset} class='w-full h-full object-contain' />
                }
                </div>
              </div>
            
          ))}
        </div>
      </div>
    </div>
  );
}

export default CoreSection;
