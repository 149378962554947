import React, { useState } from 'react'
import { InlineWidget } from 'react-calendly'
import {AiOutlineClose} from 'react-icons/ai'
import CAvid from '../assets/calendly_icon.png'

function CallToAction() {
    const [check,setcheck]=useState(false)
  return (
    <div class='w-full h-fit bg-white flex flex-col py-14 px-44 space-y-20'>
        {
            check==true?
            <div class='w-full h-screen fixed top-0 left-0 flex flex-col justify-center items-center bg-black/70 z-[999] pt-10 px-24'>
                <div class='w-full h-full flex flex-col items-end'>
                    <button onClick={()=>{setcheck(!check)}}>
                        <AiOutlineClose size='2rem' color='white'/>
                    </button>
                    <InlineWidget url='https://calendly.com/your-leads-website' styles={{width:'100%',height:'100%'}}/>
                </div>
            </div>
            :
            <></>
        }
        <div class='w-full h-fit flex flex-col bg-[#121E36] text-white rounded-xl '>
            <div class='w-full h-fit flex  px-16  '>
                <div class='flex flex-col w-1/2 py-16 justify-center font-font1 space-y-[4vh]'>
                    <div class='flex flex-col'>
                        <text class='text-[4vw] font-bold'>Daag ons uit!</text>
                        <text class='text-[1.3vw]'>Maak vrijblijvend kennis met één van onze consultants en daag ons uit als je een klik voelt!</text>
                    </div>
                    <button class='bg-[#1A76D6] font-bold text-[#E8ECEF] px-8 py-3 rounded-full w-fit' onClick={()=>{setcheck(!check)}}>Schedule Now</button>
                </div>
                <div class='w-1/2 flex justify-center items-center'>
                   <img src={CAvid} class='w-full h-[20vh] object-contain' autoPlay loop muted/>
                </div>
            </div>
        </div>
    </div> 
  )
}

export default CallToAction