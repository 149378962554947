import { Decal, Float, OrbitControls, Preload, RoundedBox, useTexture } from '@react-three/drei';
import { Canvas, useFrame } from '@react-three/fiber';
import React, { useEffect, useRef, useState } from 'react';
import logo from '../assets/boxLogo.png';
import * as THREE from 'three';
import BOX from '../Models/Rbox2'

function BoxAnimation2(props) {
  const dpTexture = useTexture(logo);
  const modelRef = useRef();

  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y = THREE.MathUtils.lerp(
        modelRef.current.rotation.y,
        props.rotation.value,
        0.05
      );
    }
  });

  return (
    <>
      <Float speed={3} rotationIntensity={3} floatIntensity={3}>
        <ambientLight intensity={1} />
        <directionalLight castShadow position={[2.5, 8, 5]} />
        <mesh castShadow receiveShadow ref={modelRef}>
          <BOX args={[2.5, 2.5, 2.5]} radius={0.3} scale={1.2}>
            <meshLambertMaterial attach="material" color={'#144282'} />
            <Decal position={[0, 0, 1]} scale={1.8} map={dpTexture} rotation={[0, 0, 0]} />
            <Decal position={[0, 0, -1]} scale={1.8} map={dpTexture} rotation={[0, Math.PI, 0]} />
          </BOX>
        </mesh>
      </Float>
    </>
  );
}

function BoxAnimation(props) {
  

  useEffect(() => {
    const interval = setInterval(() => {
      if (props.rotation.check === 2) {
        props.setRotation({ value: props.rotation.value + Math.PI, check: 0 });
      } else {
        props.setRotation({ value: props.rotation.value + Math.PI, check: props.rotation.check + 1 });
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [props.rotation]);

  return (
    <Canvas>
      <OrbitControls enableZoom={false} />
      <BoxAnimation2 rotation={props.rotation} />
      <Preload all />
    </Canvas>
  );
}

export default BoxAnimation;
