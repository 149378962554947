import React, { useState } from 'react'

import client1 from '../assets/Clients/client1.png'
import client2 from '../assets/Clients/client2.png'
import client3 from '../assets/Clients/client3.png'
import client4 from '../assets/Clients/client4.png'
import client5 from '../assets/Clients/client5.png'
import client6 from '../assets/Clients/client6.png'
import client7 from '../assets/Clients/client7.jpeg'
import client8 from '../assets/Clients/client8.png'
import client9 from '../assets/Clients/client9.png'
import client10 from '../assets/Clients/client10.svg'

function Clients() {

    const [ClientsArr, setClients]=useState([
        {name:'Logo name',image:client1},
        {name:'Logo name',image:client4},
        {name:'Logo name',image:client7},
        {name:'Logo name',image:client2},
        {name:'Logo name',image:client5},
        {name:'Logo name',image:client9},
        {name:'Logo name',image:client3},
        {name:'Logo name',image:client8},
        {name:'Logo name',image:client6},
        {name:'Logo name',image:client10},
    ])

    const [MouseCheck,setMouseCheck]=useState(-1)

  return (
    <div class='w-full h-fit flex flex-col overflow-hidden py-14 px-5 bg-white text-[#121E36] font-font1 items-center space-y-16'>
        <div class='flex flex-col items-center space-y-2'>
            <text class='font-font1 text-xl font-medium'>YOUR LEADS</text>
            <text class='font-font1 text-6xl font-bold'>Onze Opdrachtgevers</text>
        </div>
        <div className='w-full flex overflow-x-scroll'>
            <div className='flex '>
                {
                    ClientsArr.map((obj, index) =>
                        <div key={index} className=' w-[25vh] h-[20vh] flex flex-col items-center justify-center p-2 bg-white mx-2 my-2 shadow-lg border-[2px]' onMouseEnter={() => { setMouseCheck(index) }} onMouseLeave={() => { setMouseCheck(-1) }}>
                            <img src={obj.image} className='w-2/3 object-contain' />
                        </div>
                    )
                }
            </div>
        </div>

    </div>
  )
}

export default Clients

{/* <div className='w-full min-h-screen flex justify-center items-center mt-32 px-10  z-10 overflow-hidden'>
<div className='w-full relative flex items-center'>
  <div className='w-full h-full slider'>
    {Services.map((obj, index) => (
      <div key={index} className='slide'>
        <button className='w-[46vh] h-[65vh] hover:w-[50vh] hover:h-[70vh] inline-block rounded-2xl bg-gray-400 mx-2 relative overflow-hidden'>
          <img src={obj.pic} alt="Image" className="w-full h-full object-cover rounded-2xl" />
          <div className="absolute inset-0 bg-black opacity-50 hover:opacity-0 transition-opacity duration-300 z-10"></div>
          <div className="absolute flex items-end inset-0 ">
            <div className='w-full h-fit items-center py-10 bg-black/60 flex flex-col'>
              <p className='text-white font-medium text-xl z-0'>{obj.name}</p>
            </div>
          </div>
        </button>
      </div>
    ))}
  </div>
</div>
</div> */}