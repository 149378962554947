import React from 'react'

function EndingCredits() {
  return (
    <div class='w-full h-screen flex justify-center items-center px-20'>
        <div class='w-full h-[60%] flex flex-col justify-center items-center bg-black/10'>
            <div class='w-1/2 flex flex-col items-center space-y-10'>
                <text class='text-[#121E36] text-center text-[6.7vh] leading-tight font-light'>Grow your business</text>
                <text class='text-center font-light text-black/50 text-3xl'>Today is the day to build the business of your dreams. Share your mission with the world — and blow your customers away.</text>
                <button class='w-fit h-fit py-3 px-10 bg-[#121E36] text-white' >START NOW</button>
            </div>
        </div>
    </div>
  )
}

export default EndingCredits