import React, { useEffect, useRef, useState } from 'react'
import {AiFillCaretRight, AiOutlineClose} from 'react-icons/ai'
import { useInView } from 'react-intersection-observer'
import BoxAnimation from './BoxAnimation'

import bgImg from '../assets/bgImg (1).jpg'
import { InlineWidget } from 'react-calendly'


function HeroSection(props) {

    const {ref: myref, inView: myelem}= useInView()
    const [Changingtext,setChangingtext]=useState(['samen','groeien'])
    const [rotation, setRotation] = useState({check:0,value:0});

    useEffect(()=>{
        if(myelem==true)
        {
            props.setShowNavbar(false)
        }
        if(myelem==false)
        {
            props.setShowNavbar(true)
        }
    },[myelem])

    useEffect(()=>{

        if(rotation.check==0)
        {
            const arr=['Fitness','Marketing','Met onze Fitness Marketing services versterken we fitnessbedrijven met lead generatie, een verbeterde propositie en branding om nieuwe leden aan te trekken en de sportschool te laten groeien']
            setChangingtext(arr)
        }
        else if(rotation.check==1)
        {
            const arr=['Employer & Recruitment','Marketing','Met Employer & Recruitment Marketing combineren we marketingstrategieën om het werkgeversmerk te versterken, talent aan te trekken en gekwalificeerde kandidaten te werven.']
            setChangingtext(arr)
        }
        else if(rotation.check==2)
        {
            const arr=['Lokale','Bedrijfsmarketing','Onze Lokale Bedrijfsmarketing services optimaliseert de propositie, merkidentiteit en genereert leads voor lokale bedrijven door doeltreffende lead generatie en strategische branding.']
            setChangingtext(arr)
        }

    },[rotation])

    const [check,setcheck]=useState(false)

  return (
    <div  id='vanta' class='w-full min-h-screen flex flex-col'>
        <img class='absolute w-full lg:h-full h-[200vh] object-cover -z-10'  src={bgImg} />
        <div ref={myref} class='absolute w-full h-[2vh] z-10 '></div>
        <div class='w-full h-[95vh] flex lg:flex-row flex-col items-center text-[#E8ECEF] z-10 lg:pt-0 pt-28 '>
            <div class='w-full lg:w-1/2 h-fit flex flex-col items-end lg:px-0 px-20'>
                <div class='flex flex-col lg:w-[75%] w-full h-fit lg:items-start items-center space-y-[5vh]'>
                    <div class='flex flex-col space-y-[2vh]'>
                        <text class='font-font1 font-bold lg:text-[8vh] text-6xl lg:text-start text-center'>Your Leads, </text>
                        <text class='font-font1 font-bold leading-none lg:text-[4vh] text-4xl lg:text-start text-center'><text class='underline text-[#EA1179]'>{Changingtext[0]}</text> {Changingtext[1]}</text>
                    </div>
                    <text class='font-font1 text-[2vh] lg:text-start text-center'>{Changingtext[2]}</text>
                    <div class='flex w-fit text-[2vh] font-bold space-x-3'>
                        <button class='flex items-center space-x-2 bg-[#EA1179] lg:px-8 px-4 py-3 rounded-full'>
                            <text>Bekijk de video</text>
                            <div class='w-fit p-[0.8vh] flex justify-center rounded-full bg-gray-300'>
                                <AiFillCaretRight size='0.9rem' color='#EA1179'/>
                            </div>
                        </button>
                        <button class='flex items-center space-x-2 bg-[#1A76D6] lg:px-8 px-4 py-3 rounded-full' onClick={()=>{setcheck(!check)}}>
                            <text>Daag ons uit!</text>
                        </button>
                    </div>
                </div>
            </div>
            <div class='lg:w-1/2 w-full lg:h-screen h-[100vh] flex justify-center items-center'>
                <BoxAnimation rotation={rotation} setRotation={setRotation}/>
            </div>
        </div>
        {
            check==true?
            <div class='w-full h-screen fixed top-0 left-0 flex flex-col justify-center items-center bg-black/70 z-[999] pt-10 px-24'>
                <div class='w-full h-full flex flex-col items-end'>
                    <button onClick={()=>{setcheck(!check)}}>
                        <AiOutlineClose size='2rem' color='white'/>
                    </button>
                    <InlineWidget url='https://calendly.com/your-leads-website' styles={{width:'100%',height:'100%'}}/>
                </div>
            </div>
            :
            <></>
        }
    </div>
  )
}

export default HeroSection