import React, { useState } from 'react'
import Navbar from '../Components/Navbar'
import EndingCredits from '../Components/EndingCredits'

function About() {

    const [AboutPara,setAboutPara]=useState([
        'Welkom bij Your Leads, uw full-service online marketing partij die met trots haar reis heeft afgelegd naar het succes van vandaag. Wat begon als een eenvoudige behoefte in april 2020 - het bieden van betaalbare online marketing van hoge kwaliteit - heeft zich ontwikkeld tot een volwaardig online marketing bureau dat aan al uw wensen voldoet.',
        'Bij Your Leads ligt onze focus op het ondersteunen van lokale ondernemers, met een bijzondere toewijding aan de fitnessbranche. Daarnaast hebben we onze expertise uitgebreid naar Recruitment & Employer Marketing, om een bredere impact te kunnen maken. Ons team bestaat enkel uit jonge professionals met bewezen diensten, waardoor we onze opdrachtgevers een persoonlijke, down-to-earth en eerlijke aanpak kunnen bieden, zonder loze beloften.',
        'Wij geloven in het opbouwen van langdurige relaties met onze klanten, gebaseerd op vertrouwen en resultaatgerichtheid. Bij Your Leads streven we ernaar om uw online aanwezigheid te laten groeien en uw merk naar nieuwe hoogten te tillen, zonder de realiteit uit het oog te verliezen. Kies voor Your Leads en laat ons samen uw bedrijf naar succes leiden, zonder concessies te doen aan kwaliteit en professionaliteit.'
    ])

    const [TeamMembers,setTeamMembers]=useState([
        {name:'Yuri',pos:'Engineer & Marketing Consultant',pic:''},
        {name:'Thomas',pos:'Sales & Account Management',pic:''},
        {name:'Dave',pos:'All round Marketeer',pic:''},
        {name:'Sara',pos:'Junior Marketeer & Outreach',pic:''},
        {name:'Sam',pos:'Web Developer',pic:''},
    ])

  return (
    <div class='w-full min-h-screen '>
        <Navbar ShowNavbar={true}/>
        <div class='w-full min-h-screen flex flex-col items-center px-20 space-y-20 font-font1'>
            <div class='w-full h-[45vh] flex justify-center items-center mt-32 bg-[#121E36] rounded-2xl'>
                <text class='text-7xl text-white font-medium'>Over Ons</text>
            </div>
            <div class='w-[65%] flex justify-center'>
                <text class='text-[#121E36] text-center text-[6vh] leading-tight font-light'>Wij zijn er van overtuigd dat Online Marketing voor elke ondernemer, groot of klein, haalbaar en betaalbaar moet zijn.</text>
            </div>
            <div class='w-full h-[0.1vh] bg-black/20'></div>
            <div class='w-[65%] flex flex-col items-center space-y-5'>
                {
                    AboutPara.map(obj=>
                        <text class='text-center font-light text-black/40 text-lg'>{obj}</text>
                    )
                }
            </div>
            <div class='w-full h-[0.1vh] bg-black/20'></div>
            <div class='w-full flex flex-col items-center space-y-5'>
                <text class='font-light text-black/40 text-lg'>OUR PEOPLE</text>
                <text class='text-[#121E36] text-center text-[6vh] leading-tight font-light'>Our world-class team</text>
            </div>
            <div class='w-full flex flex-wrap justify-center' >
                {
                    TeamMembers.map(obj=>
                        <div class='w-[50vh] h-fit flex flex-col items-center space-y-8 mx-5 my-5'>
                            <div class='w-full h-[65vh] bg-black/10'>
                            </div>
                            <div class='flex flex-col space-y-2 items-center'>
                                <text class='text-2xl font-light text-[#121E36]'>{obj.name}</text>
                                <text class='font-light text-[#121E36]/60'>{obj.pos}</text>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
        <EndingCredits/>
    </div> 
  )
}

export default About