import React, { useEffect, useState } from 'react'
import {BiMenu} from 'react-icons/bi'
import {motion, useAnimation} from 'framer-motion'
import Logo from '../assets/LeadsLogo.png'
import { InlineWidget } from 'react-calendly'
import { AiOutlineClose } from 'react-icons/ai'

function Navbar(props) {
    const [DropDown,setDropDown]=useState(false)
    const [MenuCheck,setMenuCheck]=useState(false)

    const NavbarAnimation=useAnimation()

    function OpenMenu()
    {
        setMenuCheck(!MenuCheck)
    }

    const [offsetY,setoffsetY]=useState(0)
    const handleScroll =()=>setoffsetY(window.pageYOffset)


    useEffect(()=>{
        window.addEventListener('scroll',handleScroll)

        return ()=>window.removeEventListener('scroll',handleScroll)
    },[])


    useEffect(()=>{
        if(props.ShowNavbar==true)
        {
            NavbarAnimation.start({
                y:[-10,0],
                transition:{
                    duration:1,bounce:1
                }
            })
        }

        else
        {
            NavbarAnimation.start({
                y:0,
                transition:{
                    duration:1.5,bounce:1
                }
            })
        }
    },[props.ShowNavbar])

    const initialNavbarHeight = 0; // Set the initial height of the navbar
    const maxNavbarHeight = 80; // Set the maximum height of the navbar
    const scrollThreshold = window.innerHeight*0.8; // Set the scroll threshold to start the animation
  
    const navbarHeight = Math.min(
      maxNavbarHeight,
      initialNavbarHeight + offsetY / scrollThreshold * maxNavbarHeight
    );

    const [check,setcheck]=useState(false)

  return (
     <motion.div animate={NavbarAnimation} style={{transform:`translateY(${offsetY})`,height:navbarHeight}} class={props.ShowNavbar==true?'w-full fixed flex flex-col bg-[#ffffff] text-[#121E36]/50 z-50':'w-full fixed flex flex-col bg-transparent text-[#E8ECEF] z-50'}>
        <div class='w-full  py-3 px-20 flex justify-between items-center z-[999]'>
            <button class='w-fit flex items-center' onClick={()=>{window.location.replace('/')}}>
                <img src={Logo} class='w-32' />
            </button>
            <div class='md:flex hidden flex-row text-lg font-font1 lg:space-x-14 space-x-8 items-center'>
                <button class='hover:text-[#121E36] ' onClick={()=>{window.location.replace('/about')}}>About</button>
                <button class='hover:text-[#121E36] ' onClick={()=>{window.location.replace('/target')}}>Doelgroep</button>
                <button class='hover:text-[#121E36] ' onClick={()=>{window.location.replace('/service')}}>Services</button>  
                <button class='hover:text-[#121E36] ' onClick={()=>{window.location.replace('/contact')}}>Contact</button>  
                {/* <button class='flex items-center hover:text-[#121E36]'>Team</button>
                <button class='hover:text-[#121E36] ' onClick={()=>{window.location.replace('/')}}>Blog</button>*/}
            </div>
            <div class='md:flex hidden flex-row text-white text-lg font-font2 space-x-5 items-center'>
                <button class={`h-fit lg:py-2 py-1 lg:px-8 px-6 rounded-full ${props.ShowNavbar==true?'bg-[#121E36]':'bg-transparent border-[1px] bg-white text-[#121E36]'}`} onClick={()=>{setcheck(!check)}} >Daag ons uit!</button>
            </div>
            <div class='md:hidden flex'>
                <BiMenu size='2rem' class='hover:bg-gray-400/50 rounded-lg' onClick={OpenMenu}/>   
            </div>
        </div>
        {
            check==true?
            <div class='w-full h-screen fixed top-0 left-0 flex flex-col justify-center items-center bg-black/70 z-[999] pt-10 px-24'>
                <div class='w-full h-full flex flex-col items-end'>
                    <button onClick={()=>{setcheck(!check)}}>
                        <AiOutlineClose size='2rem' color='white'/>
                    </button>
                    <InlineWidget url='https://calendly.com/your-leads-website' styles={{width:'100%',height:'100%'}}/>
                </div>
            </div>
            :
            <></>
        }
        {/* {
            MenuCheck==true?
                <Sidebar check={props.check}/>
            :
                <></>
        } */}
    </motion.div>
  )
}

export default Navbar