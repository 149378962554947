import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import LandingPage from "./Pages/LandingPage";
import { Fragment } from "react";
import MainService from "./Pages/TargetAudience";
import TargetAudience from "./Pages/TargetAudience";
import AllServices from "./Pages/AllServices";

function App() {
  return (
   <div class='w-full min-h-screen'>
      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route exact path='/' Component={LandingPage}/>
            <Route exact path='/about' Component={About}/>
            <Route exact path='/contact' Component={Contact}/>
            <Route exact path='/target' Component={TargetAudience}/>
            <Route exact path='/service' Component={AllServices}/>
          </Routes>
        </Fragment>
      </BrowserRouter>
   </div>
  );
}

export default App;
