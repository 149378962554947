import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import '../App.css';
import s1 from '../assets/Service/s1.png';
import s2 from '../assets/Service/s2.png';
import s3 from '../assets/Service/s3.png';
import s4 from '../assets/Service/s4.png';
import s5 from '../assets/Service/s5.png';
import s6 from '../assets/Service/s6.png';
import s7 from '../assets/Service/s7.png';
import s8 from '../assets/Service/s8.png';
import s9 from '../assets/Service/s9.png';

import vid1 from '../assets/Cores/core4.gif'
import vid2 from '../assets/LeadsSection/s2.gif'
import vid3 from '../assets/LeadsSection/s3.gif'
import vid4 from '../assets/LeadsSection/s4.gif'
import vid5 from '../assets/LeadsSection/s5.gif'

import './LeadsSection.css'; // Create a CSS file for your component
import { useInView } from 'react-intersection-observer';

function LeadsSection() {

  const [types,settypes]=useState([1,2,3,4])

  const [Services, setServices] = useState([
    { name: 'Soial Ads',desc:'Effective Social Media Advertising', pic: s1,type:1,pos:1,color:'#eeeeee' },
    { name: 'Google Ads',desc:'Boost with Google Ads', pic: s2,type:1,pos:3,color:'#121E36' },
    { name: 'SEO',desc:'Optimize Website Visibility', pic: s3,type:2 ,pos:2,color:'#121E36'},
    { name: 'Email Marketing',desc:'Effective Email Marketing Solutions', pic: s4,type:2,pos:3,color:'#eeeeee'},
    { name: 'Web Building',desc:'Professional Website Creation Services', pic: s5 ,type:3,pos:1,color:'#eeeeee'},
    { name: 'Content Creation',desc:'Content Generation Expertise', pic: s6 ,type:3,pos:2,color:'#eeeeee'},
    { name: 'Soial Media Management',desc:'Social Media Management Expertise', pic: s7,type:3,pos:3,color:'#eeeeee'},
    { name: 'LinkedIn Automations',desc:'LinkedIn Automation Solutions', pic: s8 ,type:4,pos:1,color:'#121E36'},
    { name: 'Instagram Automations',desc:'Instagram Growth Solutions', pic: s9,type:4,pos:2,color:'#121E36' },
  ]);

  const {ref:ref1,inView:view1}=useInView()
  const {ref:ref2,inView:view2}=useInView()
  const {ref:ref3,inView:view3}=useInView()
  const {ref:ref4,inView:view4}=useInView()
  const {ref:ref5,inView:view5}=useInView()


  const moveTowardsCenter = {
    initial: { x: 0,y:0, opacity: 1,transition:{duration:1.5} },
    move: { x: '22vw',y:'30vh', opacity: 1,transition:{duration:1.5} },
    reset: { x: 0,y:0, opacity: 1,transition:{duration:1} },
  };

  const moveTowardsCenter2 = {
    initial: { x: 0,y:0, opacity: 1,transition:{duration:1.5} },
    move: { x: '-18vw',y:'10vh', opacity: 1,transition:{duration:1.5} },
    reset: { x: 0,y:0, opacity: 1 },
  };

  const moveTowardsCenter3 = {
    initial: { x: 0,y:0, opacity: 1,transition:{duration:1.5} },
    move: { x: '18vw',y:'-10vh', opacity: 1,transition:{duration:1.5} },
    reset: { x: 0,y:0, opacity: 1 },
  };

  const moveTowardsCenter4 = {
    initial: { x: 0,y:0, opacity: 1,transition:{duration:1.5} },
    move: { x: '-22vw',y:'-30vh', opacity: 1,transition:{duration:1.5} },
    reset: { x: 0,y:0, opacity: 1 },
  };

  const moveTowardsCenter5 = {
    initial: { x: 0,y:0, opacity: 1,transition:{duration:1.5} },
    move: { x: '22vw',y:'-40vh', opacity: 1,transition:{duration:1.5} },
    reset: { x: 0,y:0, opacity: 1 },
  };



  return (
    <div className='w-full min-h-screen flex flex-col bg-gradient-to-br to-[#1A76D6] from-[#121E36] text-[#121E36] font-font1 pt-14 px-16 pb-14 space-y-20 relative'>
      <div className='w-full flex flex-col items-center space-y-2 z-0'>
        <p className='font-font1 text-[2vh] text-[#E8ECEF] font-medium'>YOUR LEADS</p>
        <p className='font-font1 text-[6vh] text-[#E8ECEF] font-bold'>Onze Skills</p>
      </div>
      <div class='w-full h-[600vh] relative'>
        <div class='w-full h-full flex flex-col absolute'>
          <div class='w-full h-1/3'>

          </div>
          <div ref={ref1} class='w-full h-[20vh]'>

          </div>
          <div class='w-full h-1/3'>

          </div>
          <div ref={ref2} class='w-full h-[20vh]'>

          </div>
          <div class='w-full h-1/3'>

          </div>
          <div ref={ref3} class='w-full h-[20vh]'>

          </div>
          <div class='w-full h-1/3'>

          </div>
          <div ref={ref4} class='w-full h-[20vh]'>

          </div>
          <div class='w-full h-1/3'>

          </div>
          <div ref={ref5} class='w-full h-[20vh]'>

          </div>
          <div class='w-full h-1/3'>

          </div>
        </div>
        <div  class='w-full h-screen sticky top-[7vh]'>
          <motion.div
        
            variants={moveTowardsCenter}
            initial='initial'
            animate={view1 ? 'move' : 'initial'}
            exit='reset'
            class={`top-0 left-0 absolute flex  items-center ${view2==true||view3==true||view4==true||view5==true?'bg-white/40':''} ${view1==true?'w-[40vw] h-fit z-50':'w-[35vw] h-fit'} w-[40vw]  bg-[#E8ECEF] backdrop-blur-md rounded-2xl p-2`}
          >
            <div class='w-1/4 h-fit rounded-2xl'>
              <img src={vid1} class='w-full h-[20vh] object-cover'/>
            </div>
            <div class='w-3/4 h-fit flex flex-col p-5 space-y-5'>
              <div class='flex flex-col'>
                <text class='text-[4vh] font-bold'>Social Media</text>
                <text class='text-[2vh]'>Advertenties, Social Media Management</text>
              </div>
              {
                view1==true?
                <text class='text-[2vh]'>Ik heb geleerd over adverteren en het beheren van sociale media, waarbij ik effectieve campagnes heb opgezet en verschillende platforms heb beheerd.</text>
                :
                <></>
              }
            </div>
          </motion.div>
          <motion.div
           
            variants={moveTowardsCenter2}
            initial='initial'
            animate={view2 ? 'move' : 'initial'}
            class={`top-[20vh] right-[10vh] absolute flex  items-center ${view1==true||view3==true||view4==true||view5==true?'bg-white/40':''} ${view2==true?'w-[40vw] h-fit z-50':'w-[35vw] h-fit'} w-[40vw]  bg-[#E8ECEF] backdrop-blur-md rounded-2xl p-2`}
          >
            <div class='w-1/4 h-fit rounded-2xl'>
              <img src={vid2} class='w-full h-[20vh] object-cover'/>
            </div>
            <div class='w-3/4 h-fit flex flex-col p-5 space-y-5'>
              <div class='flex flex-col'>
                <text class='text-[4vh] font-bold'>Google</text>
                <text class='text-[2vh]'>Google Ads, SEO, Youtube Ads</text>
              </div>
              {
                view2==true?
                <text class='text-[2vh] '>Verkend digitale marketing via Google Ads voor doelgerichte campagnes, SEO-technieken voor online zichtbaarheid en YouTube Ads voor gerichte outreach.</text>
                :
                <></>
              }
            </div>
          </motion.div>
          <motion.div
          
            variants={moveTowardsCenter3}
            initial='initial'
            animate={view3 ? 'move' : 'initial'}
            exit='reset'
            class={`top-[40vh] left-[10vh] absolute flex flex-row-reverse items-center ${view2==true||view1==true||view4==true||view5==true?'bg-white/40':''} ${view3==true?'w-[40vw] h-fit z-50':'w-[35vw] h-fit'} w-[40vw]  bg-[#E8ECEF] backdrop-blur-md rounded-2xl p-2`}
          >
            <div class='w-1/4 h-fit rounded-2xl'>
              <img src={vid3} class='w-full h-[20vh] object-cover'/>
            </div>
            <div class='w-3/4 h-fit flex flex-col  p-5 space-y-5'>
              <div class='flex flex-col items-end'>
                <text class='text-[4vh] font-bold'>Automations</text>
                <text class='text-[2vh] text-end'>Email Marketing, Instagram and LinkedIn</text>
              </div>
              {
                view3==true?
                <text class='text-[2vh] text-end'>Binnen het domein van automatisering heb ik mij verdiept in gebieden zoals E-mailmarketing, Instagram en LinkedIn, waarbij ik processen stroomlijnde en efficiëntie verbeterde.</text>
                :
                <></>
              }
            </div>
          </motion.div>
          <motion.div
           
            variants={moveTowardsCenter4}
            initial='initial'
            animate={view4 ? 'move' : 'initial'}
            exit='reset'
            class={`top-[60vh] right-[2vh] absolute flex  items-center ${view2==true||view3==true||view1==true||view5==true?'bg-white/40':''} ${view4==true?'w-[40vw] h-fit z-50':'w-[35vw] h-fit'} w-[40vw]  bg-[#E8ECEF] backdrop-blur-md rounded-2xl p-2`}
            >
              <div class='w-1/4 h-fit rounded-2xl'>
                <img src={vid4} class='w-full h-[20vh] object-cover'/>
              </div>
              <div class='w-3/4 h-fit flex flex-col p-5 space-y-5'>
                <div class='flex flex-col'>
                 <text class='text-[4vh] font-bold'>Content</text>
                  <text class='text-[2vh]'>Fotografie, Videocontent and Social Media</text>
                </div>
                {
                  view4==true?
                  <text class='text-[2vh]'>Ik heb ervaring opgedaan met fotografie, videocontent en social media binnen het gebied van contentcreatie, waarbij ik boeiende visuele verhalen heb gemaakt die aanspreken.</text>
                  :
                  <></>
                }
              </div>
          </motion.div>
          <motion.div
           
            variants={moveTowardsCenter5}
            initial='initial'
            animate={view5 ? 'move' : 'initial'}
            exit='reset'
            class={`top-[70vh] left-[2vh] absolute flex  items-center ${view2==true||view3==true||view4==true||view1==true?'bg-white/40':''} ${view5==true?'w-[40vw] h-fit z-50':'w-[35vw] h-fit'} w-[40vw]  bg-[#E8ECEF] backdrop-blur-md rounded-2xl p-2`}
          >
            <div class='w-1/4 h-fit rounded-2xl'>
              <img src={vid5} class='w-full h-[20vh] object-cover'/>
            </div>
            <div class='w-3/4 h-fit flex flex-col p-5 space-y-5'>
              <div class='flex flex-col'>
                <text class='text-[4vh] font-bold'>Web Building</text>
                <text class='text-[2vh]'>Webshop's, Landingspagina's and Websites</text>
              </div>
              {
                view5==true?
                <text class='text-[2vh]'>Ik ben gespecialiseerd in het bouwen van webshops, landingspagina's en websites voor diverse zakelijke behoeften.</text>
                :
                <></>
              }
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default LeadsSection;

