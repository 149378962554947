import React, { useState } from 'react'
import {BsQuote} from 'react-icons/bs'

function QuoteSection() {

  return (
    <div class='w-full h-fit flex justify-center items-center  px-24 py-24 font-font1'>
        <div class='w-full h-fit flex  py-10 pl-20 bg-gradient-to-br from-[#bA1179] to-[#1A76D6] rounded-2xl'>
            <div class='w-[60%] flex flex-col space-y-5'>
                <BsQuote size='5.5rem' color='white'/>
                <text class='text-white text-3xl ml-5'>Bij Your Leads bieden wij online marketing aan wat voor elke ondernemer, groot of klein, haalbaar en betaalbaar is</text>
                <text class='text-white ml-5 font-bold text-xl font-serif'> - Yuri Bakker | <text class='font-light font-font1'>Eigenaar Your Leads</text></text>
            </div>
            <div class='w-[40%] flex justify-center items-center'>
                <div class='w-[25vh] h-[25vh] rounded-full bg-white'>

                </div>
            </div>
        </div>
    </div>
  )
}

export default QuoteSection