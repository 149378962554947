import { useInView } from 'react-intersection-observer'
import React, { useState } from 'react'
import { motion } from 'framer-motion';

function WorkFlow() {

    const [steps,setsteps]=useState([
        {content:''},
        {content:''},
        {content:''},
        {content:''},
        {content:''},
    ])

    const {ref:Ultraref,inView:view0}=useInView()
    const {ref:ref1,inView:view1}=useInView()
    const {ref:ref2,inView:view2}=useInView()
    const {ref:ref3,inView:view3}=useInView()
    const {ref:ref4,inView:view4}=useInView()
    const {ref:ref5,inView:view5}=useInView()

    const moveTowardsCenter = {
        initial: {y:0, opacity: 1,transition:{duration:1} },
        move: { y:'-60vh', opacity: 1, transition:{duration:1}},
        move2: { y:'-120vh', opacity: 1, transition:{duration:1}},
        move3: { y:'-180vh', opacity: 1,transition:{duration:1} },
        move4: { y:'-240vh', opacity: 1,transition:{duration:1} },
    };

  return (
    <div class='w-full min-h-screen font-font1'>
        <div ref={Ultraref} class='w-full h-[500vh] relative'>
            <div class='w-full h-full flex flex-col absolute'>
                <div class='h-[10vh]'>

                </div>
                <div ref={ref1} class='w-full h-screen'>

                </div>
                <div ref={ref2} class='w-full h-screen'>

                </div>
                <div ref={ref3} class='w-full h-screen '>
                    
                </div>
                <div ref={ref4} class='w-full h-screen'>
                    
                </div>
                <div ref={ref5} class='w-full h-screen'>
                    
                </div>
            </div>
            <div class='w-full h-[90vh] flex items-start px-24 pt-32 sticky top-24 space-x-[10vh] overflow-hidden'>
                <div class='flex flex-col w-1/2  space-y-5'>
                    <div class='flex space-x-10'>
                        <button className='font-font1 text-[3vh] font-medium hover:underline'>OVER ONS</button>
                        <button className='font-font1 text-[3vh] font-medium hover:underline'>Contact</button>
                    </div>
                    <h1 className='text-[15vh] font-bold leading-none text-[#1A76D6]'>Onze Werkwijze</h1>
                    
                </div>
                <div class='w-1/2 h-[100vh] overflow-hidden flex flex-col space-y-10'>
                    <div className='sticky top-24 space-y-[10vh] flex flex-col'>
                        <motion.div className='w-full h-[50vh] flex bg-[#E8ECEF] shadow-xl border-[2px] rounded-tr-[10vh] rounded-bl-[10vh] px-[8vh] py-[4vh] items-center'>
                            <div className='w-full flex flex-col space-y-[5vh] '>
                                <div class='flex space-x-5 items-center text-4xl font-bold'>
                                    <div class='bg-gradient-to-br from-[#1A76D6] to-[#121E36] text-[3vh] text-white py-[1vh] px-[2vh] rounded-full'>1</div>
                                    <h2 className='text-black text-[4vh]'>On-Boarding</h2>
                                </div>
                            
                                <p className='text-black text-[2vh]'>Voorafgaand aan het strijdplan gesprek vragen we je een kort stappenplan inclusief vragenlijst te doorlopen om ons van de juiste informatie en benodigdheden te voorzien zodat niks ons in de weg staat om de samenwerking te starten.</p>
                            </div>
                        </motion.div>
                        <motion.div 
                        variants={moveTowardsCenter}
                        initial='initial'
                        animate={view2||view3||view4|view5 ? 'move' : 'initial'}
                        exit='move'
                        className='w-full h-[50vh] flex items-center bg-[#E8ECEF] shadow-xl border-[2px] rounded-tr-[10vh] rounded-bl-[10vh] px-[8vh] py-[4vh]'>
                             <div className='w-full flex flex-col space-y-[5vh]'>
                                <div class='flex space-x-5 items-center text-4xl font-bold'>
                                    <div class='bg-gradient-to-br from-[#1A76D6] to-[#121E36] text-[3vh] text-white py-[1vh] px-[2vh] rounded-full'>2</div>
                                    <h2 className='text-black text-[4vh]'>Strijdplan gesprek</h2>
                                </div>
                            
                                <p className='text-black text-[2vh]'>Tijdens het strijdplan gesprek zullen opdrachtgever en de aangewezen accountmanager verder kennismaken en wordt de on-boarding kort nagelopen. In dit gesprek zal de accountmanager zijn/haar verdere vragen stellen om jouw bedrijf zo goed mogelijk te begrijpen. Met deze informatie wordt vervolgens samen met de opdrachtgever een plan gemaakt die de accountmanager vervolgens zal uitzetten binnen het team.</p>
                            </div>
                        </motion.div>
                        <motion.div 
                         variants={moveTowardsCenter}
                         initial='initial'
                         animate={view3||view4||view5 ? 'move2' :[view2?'move': 'initial']}
                         exit='move'
                        className='w-full h-[50vh] bg-[#E8ECEF] shadow-xl border-[2px] rounded-tr-[10vh] rounded-bl-[10vh] flex items-center px-[8vh] py-[4vh]'>
                             <div className='w-full flex flex-col space-y-[5vh]'>
                                <div class='flex space-x-5 items-center text-4xl font-bold'>
                                    <div class='bg-gradient-to-br from-[#1A76D6] to-[#121E36] text-[3vh] text-white px-[2vh] py-[1vh] rounded-full'>3</div>
                                    <h2 className='text-black text-[4vh]'>Werkzaamheden</h2>
                                </div>
                            
                                <p className='text-black text-[2vh]'>Bij Your Leads willen, streven en behalen we altijd de beste kwaliteit voor onze opdrachtgevers. Zo bieden wij enkel op maat gemaakte oplossingen die aansluiten bij de wensen en doelen die vooraf besproken zijn. Op deze manier halen wij het onderste uit de fles en bieden wij maximale resultaten.</p>
                            </div>
                        </motion.div>
                        <motion.div
                         variants={moveTowardsCenter}
                         initial='initial'
                         animate={view4||view5 ? 'move3' :[view3?'move2':[view2?'move': 'initial']]}
                         exit='move'
                        className='w-full h-[50vh] bg-[#E8ECEF] shadow-xl border-[2px] rounded-tr-[10vh] rounded-bl-[10vh] px-[8vh] py-[4vh] flex items-center'>
                             <div className='w-full flex flex-col space-y-[5vh]'>
                                <div class='flex space-x-5 items-center text-4xl font-bold'>
                                    <div class='bg-gradient-to-br from-[#1A76D6] to-[#121E36] text-[3vh] text-white px-[2vh] py-[1vh] rounded-full'>4</div>
                                    <h2 className='text-black text-[4vh]'>Contactmomenten</h2>
                                </div>
                            
                                <p className='text-black text-[2vh]'>Communicatie vinden wij erg belangrijk in onze samenwerkingen. Daarom vinden er regelmatig contactmomenten plaats waarin er even kort van gedachte wordt gewisseld over de voortgang van de samenwerking. Kort & bondig, want als opdrachtgever heb jij het al druk zat.</p>
                            </div>
                        </motion.div>
                        <motion.div 
                         variants={moveTowardsCenter}
                         initial='initial'
                         animate={
                            view5
                              ? 'move4'
                              : view4
                              ? 'move3'
                              : view3
                              ? 'move2'
                              : view2
                              ? 'move'
                              : 'initial'
                          }
                         exit='move'
                        className='w-full h-[50vh] bg-[#E8ECEF] shadow-xl border-[2px] rounded-tr-[10vh] rounded-bl-[10vh] flex items-center px-[8vh] py-[4vh]' >
                             <div className='w-full flex flex-col space-y-[5vh]'>
                                <div class='flex space-x-5 items-center text-4xl font-bold'>
                                    <div class='bg-gradient-to-br from-[#1A76D6] to-[#121E36] text-[3vh] text-white px-[2vh] py-[1vh] rounded-full'>5</div>
                                    <h2 className='text-black text-[4vh]'>Periodieke afspraken</h2>
                                </div>
                            
                                <p className='text-black text-[2vh]'>Wij vinden het belangrijk om de neuzen dezelfde kant op te houden. Daarom streven wij ernaar om minimaal eens in het kwartaal een face-to-face afspraak in te plannen, uiteraard afhankelijk van de wensen van de opdrachtgever. Tijdens deze periodieke afspraak houden we controle over de richting en bespreken we de relevante ontwikkelingen in de markt.</p>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WorkFlow