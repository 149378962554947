import React, { useState } from 'react'
import Navbar from '../Components/Navbar'
import EndingCredits from '../Components/EndingCredits'

import s1 from '../assets/Service/s1.png';
import s2 from '../assets/Service/s2.png';
import s3 from '../assets/Service/s3.png';
import s4 from '../assets/Service/s4.png';
import s5 from '../assets/Service/s5.png';
import s6 from '../assets/Service/s6.png';
import s7 from '../assets/Service/s7.png';
import s8 from '../assets/Service/s8.png';
import s9 from '../assets/Service/s9.png';

function AllServices() {

    const [Services, setServices] = useState([
        { name: 'Soial Ads',desc:'Effective Social Media Advertising', pic: s1,type:1,pos:1,color:'#eeeeee' },
        { name: 'Google Ads',desc:'Boost with Google Ads', pic: s2,type:1,pos:3,color:'#121E36' },
        { name: 'SEO',desc:'Optimize Website Visibility', pic: s3,type:2 ,pos:2,color:'#121E36'},
        { name: 'Email Marketing',desc:'Effective Email Marketing Solutions', pic: s4,type:2,pos:3,color:'#eeeeee'},
        { name: 'Web Building',desc:'Professional Website Creation Services', pic: s5 ,type:3,pos:1,color:'#eeeeee'},
        { name: 'Content Creation',desc:'Content Generation Expertise', pic: s6 ,type:3,pos:2,color:'#eeeeee'},
        { name: 'Soial Media Management',desc:'Social Media Management Expertise', pic: s7,type:3,pos:3,color:'#eeeeee'},
        { name: 'LinkedIn Automations',desc:'LinkedIn Automation Solutions', pic: s8 ,type:4,pos:1,color:'#121E36'},
        { name: 'Instagram Automations',desc:'Instagram Growth Solutions', pic: s9,type:4,pos:2,color:'#121E36' },
    ]);

    const [ShowService,setShowService]=useState({count:0,service:null})

  return (
    <div class='w-full min-h-screen '>
        <Navbar ShowNavbar={true}/>
        <div class='w-full min-h-screen flex flex-col items-center px-20 space-y-20 font-font1'>
           {
            ShowService.count==0?
                <div class='w-full h-[45vh] flex justify-center items-center mt-32 bg-[#121E36] rounded-2xl'>
                    <text class='text-7xl text-white font-medium'>Services</text>
                </div>
            :
            <div className='w-fit h-fit justify-center relative flex mt-28'>
               <img src={ShowService.service.pic} className='w-[100%] object-contain' style={{backgroundColor:ShowService.service.color}}   />
               <div class='absolute w-[80%] h-fit flex justify-center  bg-black/50 p-5'>
                <text class='text-white text-2xl font-light'>{ShowService.service.name}</text>
               </div>
            </div>
          
            }
            {
                ShowService.count!=0?
                    <div class='w-full flex flex-col space-y-20 p-10'>
                        <div class='w-full flex flex-col space-y-8'>
                            <div class='flex flex-col font-medium space-y-1'>
                                <text class='text-[#1A76D6] text-lg'> - {ShowService.service.name}</text>
                                <text class='text-4xl font-bold'>Introduction</text>
                            </div>
                            {/* <div class='flex flex-col space-y-3'>
                                {
                                    ShowService.service.details.map(obj=>
                                        <text class='text-black/50 text-xl'>{obj}</text>
                                    )
                                } 
                            </div> */}
                            <text class='text-black/50 text-xl'>{ShowService.service.desc}</text>
                        </div>
                    </div>
                :
                    <></>
            }
            {
                    ShowService.count!=0?
                    <div class='w-full h-[0.1vh] bg-black/20'></div>
                    :
                    <></>
            }
            <div class='w-full flex flex-col items-center space-y-5'>
                <text class='font-light text-black/40 text-lg'>What we are best at</text>
                {
                    ShowService.count!=0?
                    <text class='text-[#121E36] text-center text-[6vh] leading-tight font-light'>Other Services</text>
                    :
                    <text class='text-[#121E36] text-center text-[6vh] leading-tight font-light'>Services</text>
                }
            </div>
            <div class='w-full h-fit flex flex-wrap justify-center'>
                {
                    Services.map((obj,index)=>
                        index+1!=ShowService.count?
                        <div class='w-[55vh] h-[75vh] flex flex-col p-5 border-[2px] border-[#121E36] rounded-xl justify-between my-5 mx-5'>
                            <img src={obj.pic} class='w-full h-[60%] rounded-xl object-contain' style={{backgroundColor:obj.color}} />
                            <div class='flex flex-col space-y-5 items-center'>                           
                                <text class='text-[#121E36] text-center text-2xl leading-tight font-medium'>{obj.name}</text>
                                <text class='font-light text-black/40 text-sm'>{obj.desc}</text>
                                <button class='w-fit bg-[#121E36] px-8 py-2 rounded-xl text-white' onClick={()=>{setShowService({count:index+1,service:obj});window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });}}>MORE</button>
                            </div>
                        </div> 
                        :
                        <></> 
                    )
                }
            </div>    
        </div>
        <EndingCredits/>
    </div> 
  )
}

export default AllServices